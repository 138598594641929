* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #c9d2e3;
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

button {
    cursor: pointer;
    border: 0;
}

a {
    cursor: pointer;
    text-decoration: underline;
}

/** new here **/

.admin-header {
    width: 100%;
    max-width: 720px;
    margin-top: 14px;
}

.nav-header {
    width: 100%;
    background-color: #FFF;
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding-left: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav-header button {
    border: 0;
    background-color: transparent;
    margin-right: 24px;
    margin-left: 8px;
}

.nav-header a {
    margin-right: 14px;
    color: #21242d;
    transition: color 0.2s;
}

.nav-header a:hover {
    color: #eda617;
}

.shadow {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15) !important;
}

.logo {
    margin-top: 10px;
    margin-bottom: 28px;
    color: #fff;
    font-size: 54px;
}

.logo-text {
    background: linear-gradient(#E18700, #FFD43a);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 460px) {
    .logo {
        font-size: 50px;
    }
}

@media screen and (max-width: 400px) {
    .logo {
        font-size: 45px;
    }
}

@media screen and (max-width: 360px) {
    .logo {
        font-size: 40px;
    }
}

.admin-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding: 0 6px 24px 6px;
}

.label {
    color: #000;
    font-weight: medium;
    margin-top: 4px;
    margin-bottom: 2px;
}

.container-colors {
    display: flex;
    margin: 14px 0;
    gap: 22px;
}

.right {
    margin-right: 14px;
}

.btn-register {
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-register svg {
    margin-right: 8px;
}

.title {
    color: #657eae;
    margin: 28px 0 14px 0;
}

.list {
    width: 90%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(36, 36, 36, 0.35);
    border-radius: 4px;
    padding: 14px 8px;
    margin-bottom: 14px;
}

.list-text {
    color: #657eae;
    font-weight: bold;
}

.groupcheck {
    display: flex;
    align-items: center;
    flex-direction: row;
    vertical-align: text-top;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.footer button {
    height: 36px;
    border-radius: 4px;
    background-color: #003768;
    border: 0;
    font-size: 18px;
    padding: 10px;
    color: #fff;
    margin-right: 10px;
}

.btn-delete {
    border: 1.5px dashed #FFF;
    color: #FFF;
    background-color: #000;
    padding: 4px 8px;
    border-radius: 2px;
}

.head-form {
    width: 90%;
    max-width: 600px;
}

.btn-close {
    color: #FFF;
    background-color: #003768;
    padding: 4px;
    border-radius: 5px;
}

.btn-view {
    /* border: 1.5px dashed #FFF; */
    color: #657eae;
    padding: 4px 8px;

}

.btn-view:hover {
    /* border: 1.5px dashed #FFF; */
    color: green;

}

.animate-pop {
    animation-duration: 0.5s;
    animation-name: animate-pop;
}

@keyframes animate-pop {
    0% {
        opacity: 0;
        transform: scale(0.7, 0.7);
    }

    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}


.form {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 600px;
}

.form button {
    height: 36px;
    border-radius: 4px;
    background-color: #003768;
    border: 0;
    font-size: 18px;
    color: #fff;
}

.form hr {
    color: #fff;
}

.input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input-row2 {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input-group {
    flex: 1;
    margin-right: 10px;
}

.input-group input[type="tel"],
.input-group input[type="date"] {
    width: 100%;
    padding: 10px;
}

.custom-input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;

}